import React, { useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";

import { getHttpWeb3 } from "../utils";

import { DEGEN_DICE_MATIC_ADDRESS } from "../constants";

import DegenDiceMaticAbi from "../constants/DegenDiceMatic.json";

import Duo1 from "../assets/Duo1.png";
import Matic from "../assets/Matic .png";

export default function ClashesLandingMatic() {
  /**
    gameId uint256: 1
    numPlayers uint256: 2
    bet uint256: 0
    bonus uint256: 0
    gameState uint8: 1
    creator address: 0xcd889634eb2495cab8816ea853b6838f3921165d
    players address[]: []
   **/
  const [games, setGames] = useState([]);

  const nav = useNavigate();

  useEffect(() => {
    (async () => {
      const web3 = getHttpWeb3();
      const degenDiceMatic = new web3.eth.Contract(
        DegenDiceMaticAbi,
        DEGEN_DICE_MATIC_ADDRESS
      );

      const _last =
        parseInt(await degenDiceMatic.methods.nextGameId().call()) || 0;

      let _games = [];

      for (let i = 1; i < _last; i++) {
        let gameInfo = (await degenDiceMatic.methods.games(i).call()) || {};
        gameInfo["players"] = await degenDiceMatic.methods.getPlayers(i).call();
        if (parseInt(gameInfo?.numPlayers) !== gameInfo["players"]?.length) {
          console.log(
            gameInfo,
            parseInt(gameInfo?.numPlayers),
            gameInfo["players"]?.length
          );
          _games.push(gameInfo);
          setGames([..._games]);
        }
      }
    })();
  }, []);
  return (
    <div className="flex flex-col ml-4 mb-5 space-y-4 border-r-2 pr-4">
      <p className="text-3xl text-[#E3109E] text-center font-bold mb-5">
        MATIC Clashes
      </p>
      {games.map((item, index) => {
        if (index >= 3) {
          return null;
        }
        return (
          <div
            className="flex flex-col md:flex-row space-x-2 mb-2 cursor-pointer justify-center"
            key={item?.gameId}
            onClick={() => nav(`/play/${item?.gameId}`)}
          >
            <img className="m-2" alt="duo1" src={Duo1} />
            <div className="flex flex-col m-2">
              <p className="text-2xl font-bold mb-4">Clash #{item?.gameId}</p>
              <div className="flex flex-row space-x-2 text-xl mb-4">
                {new Array(item?.players?.length || 0)
                  .fill(0)
                  .map((item, index) => {
                    return (
                      <BsCircleFill
                        className="text-black"
                        key={"black" + index}
                      />
                    );
                  })}
                {new Array(parseInt(item?.numPlayers) - item?.players?.length)
                  .fill(0)
                  .map((item, index) => {
                    return (
                      <BsCircleFill
                        className="text-[#E3109E]"
                        key={"red" + index}
                      />
                    );
                  })}
                {/* <BsCircleFill className="text-black" />
                  <BsCircleFill className="text-black" /> */}
                {/* <BsCircleFill className="text-[#E3109E]" />
                  <BsCircleFill className="text-[#E3109E]" />
                  <BsCircleFill className="text-[#E3109E]" /> */}
              </div>
              <div className="flex flex-row space-x-2">
                <div className="rounded-lg border flex flex-col mx-auto w-28 text-center items-center align-center justify-center">
                  <img
                    alt="matic logo"
                    src={Matic}
                    className="w-1/2 h-1/2 mx-auto align-center justify-center items-center"
                  />
                  <p className="text-center">MATIC</p>
                </div>
                <div className="rounded-lg border flex flex-col mx-auto w-28 text-center items-center align-center justify-center ">
                  <p>{item?.numPlayers} player</p>
                  <p>Clash</p>
                </div>
                <div className="rounded-lg border flex flex-col w-28 mx-auto text-center items-center align-center justify-center ">
                  <p className="text-center">Bet</p>
                  <p className="text-center">
                    {Web3.utils.fromWei(item?.bet || "")} Matic
                  </p>
                </div>
                <div className="rounded-lg border flex flex-col w-28 mx-auto text-center items-center align-center justify-center ">
                  <p className="text-center">Bonus</p>
                  <p className="text-center">
                    {Web3.utils.fromWei(item?.bonus || "")} Matic
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {games.length > 3 && (
        <div className="flex w-full justify-center align-center">
          <button
            class="bg-[#E3109E] hover:bg-[#E3109E] text-white font-bold py-2 px-4 rounded-full"
            onClick={() => {
              nav("/all-clashes");
            }}
          >
            Find more clashes
          </button>
        </div>
      )}
    </div>
  );
}
