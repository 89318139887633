import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";
import AllClashesTable from "../components/AllClashesTable";

import { getHttpWeb3 } from "../utils";

import { DEGEN_DICE_MATIC_ADDRESS } from "../constants";

import DegenDiceMaticAbi from "../constants/DegenDiceMatic.json"

import Banner from "../assets/Fullbanner.png";

export default function AllClashes() {
  const [games, setGames] = useState([]);

  useEffect(() => {
    (async () => {
      const web3 = getHttpWeb3();
      const degenDiceMatic = new web3.eth.Contract(DegenDiceMaticAbi, DEGEN_DICE_MATIC_ADDRESS);

      const _nextId = parseInt(await degenDiceMatic.methods.nextGameId().call()) || 0;

      let _games = [];
      for (let i = 1; i < _nextId; i++) {
        let gameInfo = await degenDiceMatic.methods.games(i).call() || {};
        gameInfo["players"] = await degenDiceMatic.methods.getPlayers(i).call();

        _games.push(gameInfo);

        setGames([..._games])
      }

    })()
  }, [])

  return (
    <Layout>
      <div className="flex align-center mx-auto items-center justify-center mt-2 w-11/12 mb-4">
        <img alt="Banner" src={Banner} />
      </div>
      <AllClashesTable clashesData={games} />
    </Layout>
  );
}
