import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import BigNumber from "bignumber.js";
import Web3 from "web3";
import { useParams } from "react-router-dom";
import { TwitterShareButton } from "react-share";

import Layout from "../components/Layout";

import { connectWallet, getHttpWeb3 } from "../utils";
import { DEGEN_DICE_MATIC_ADDRESS } from "../constants";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";
import { PersonCard } from "../components/PersonCard";
import Loading from "../components/Loading";

import DegenDiceMaticAbi from "../constants/DegenDiceMatic.json";

import CreateBanner from "../assets/CreateBanner.png";
import Matic from "../assets/Matic .png";
import Person3 from "../assets/Person3.png";
import Person1 from "../assets/Person1.png";
import Rolling from "../assets/Rolling .png";

import QuestionDie from "../assets/QuestionDie.png";
import Dice1Img from "../assets/1die.png";
import Dice2Img from "../assets/2die.png";
import Dice3Img from "../assets/3die.png";
import Dice4Img from "../assets/4die.png";
import Dice5Img from "../assets/5die.png";
import Dice6Img from "../assets/6die.png";

import QuestionPinkDie from "../assets/PinkQDie.jpg";
import PinkDice1Img from "../assets/Pink1Die.jpg";
import PinkDice2Img from "../assets/Pink2Die.png";
import PinkDice3Img from "../assets/Pink3Die.png";
import PinkDice4Img from "../assets/Pink4Die.png";
import PinkDice5Img from "../assets/Pink5Die.png";
import PinkDice6Img from "../assets/Pink6Die.png";

import XlogoImg from "../assets/Xlogo.png";

import { Modal, Box, Grid } from "@mui/material";

const DICE_IMAGES = [
  QuestionDie,
  Dice1Img,
  Dice2Img,
  Dice3Img,
  Dice4Img,
  Dice5Img,
  Dice6Img,
];
const PINK_DICE_IMAGES = [
  QuestionPinkDie,
  PinkDice1Img,
  PinkDice2Img,
  PinkDice3Img,
  PinkDice4Img,
  PinkDice5Img,
  PinkDice6Img,
];

const PlayCard = ({ loadData }) => {
  const [open, setOpen] = useState(false);
  const [resOpen, setResOpen] = useState(false);
  const [compareOpen, setCompareOpen] = useState(false);

  const [myaccount, setMyaccount] = useState("");
  const [rollResults, setRollResults] = useState({});
  const [leadingStatus, setLeadingStatus] = useState(false);
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(false);

  const { diceId } = useParams();

  const GameState = { Active: "0", Finished: "1", Cancelled: "2" };
  const [gameInfo, setGameInfo] = useState({});

  const playGame = async () => {
    const web3 = await connectWallet();
    if (typeof web3 === "object") {
      const accounts = await web3.eth.getAccounts(function (error, accounts) {
        if (error) {
          return "";
        }
        return accounts[0];
      });
      const account = accounts[0];
      setMyaccount(account);
      const degenDiceMatic = new web3.eth.Contract(
        DegenDiceMaticAbi,
        DEGEN_DICE_MATIC_ADDRESS
      );
      const _gameInfo =
        (await degenDiceMatic.methods.games(parseInt(diceId)).call()) || {};
      setGameInfo(_gameInfo);
      if (_gameInfo.gameState !== GameState.Active) {
        return toast("Game is not active.", customToastStyle);
      }
      const _prevPlayers =
        (await degenDiceMatic.methods.getPlayers(parseInt(diceId)).call()) ||
        [];
      if (_prevPlayers.length >= parseInt(_gameInfo.numPlayers)) {
        return toast("Game is full.", customToastStyle);
      }
      const _balance = await web3.eth.getBalance(account);
      if (BigNumber(_balance).isLessThan(BigNumber(_gameInfo.bet))) {
        return toast(
          "You don't have enough MATIC to play this game",
          customToastStyle
        );
      }
      const _rollResults = await degenDiceMatic.methods
        .getRollResults(diceId, account)
        .call();
      const filteredRollResults =
        _rollResults.filter((item) => item !== "0") || [];
      if (filteredRollResults.length !== 0) {
        return toast("You already took part in this game.", customToastStyle);
      }
      setOpen(true);

      setLoading(true);

      try {
        await degenDiceMatic.methods
          .playGame(diceId)
          .send({ from: account, value: _gameInfo.bet });
        toast("Success", customToastSuccessStyle);
      } catch (e) {
        setOpen(false);
        console.log(e);
        return toast(
          "There is an error to interact smart contract.",
          customToastStyle
        );
      } finally {
        setLoading(false);
      }

      const players =
        (await degenDiceMatic.methods.getPlayers(parseInt(diceId)).call()) ||
        [];
      let resultsObj = {};
      for (let i = 0; i < players.length; i++) {
        const _rollResults = await degenDiceMatic.methods
          .getRollResults(diceId, players[i])
          .call();
        resultsObj = { ...resultsObj, [players[i]]: { result: _rollResults } };
      }
      let mySum = 0;
      let leading = false;
      let _finished = players.length === parseInt(_gameInfo.numPlayers);
      if (resultsObj[account]) {
        let indObj = resultsObj[account];
        mySum = indObj["result"].reduce(function (total, num) {
          return parseInt(total) + parseInt(num);
        }, 0);
        indObj.sum = mySum;
        resultsObj = { ...resultsObj, [account]: indObj };
      }
      let maxSum = 0;
      for (const item in resultsObj) {
        let indObj = resultsObj[item];
        const personSum = indObj["result"].reduce(function (total, num) {
          return parseInt(total) + parseInt(num);
        }, 0);
        indObj.sum = personSum;
        resultsObj = { ...resultsObj, [item]: indObj };
        if (personSum > maxSum) {
          maxSum = personSum;
        }
      }
      if (mySum === maxSum && _finished) {
        leading = true;
      } else {
        leading = false;
      }
      // Object.keys(resultsObj).forEach((item) => {
      //   let indObj = resultsObj[item];
      //   const personSum = indObj["result"].reduce(function (total, num) {
      //     return parseInt(total) + parseInt(num);
      //   }, 0);
      //   indObj.sum = personSum;
      //   resultsObj = { ...resultsObj, [item]: indObj };
      //   if (mySum > personSum) {
      //     leading = true;
      //   } else {
      //     leading = false;
      //   }
      // });
      setLeadingStatus(leading);
      setRollResults(resultsObj);
      setFinished(_finished);
      setOpen(false);
      setResOpen(true);
    }
  };

  return (
    <div className="flex justify-center flex-col">
      {loading && <Loading opacity="0.8" />}
      <button
        className="bg-[#E3109E] text-center w-full p-2 text-4xl font-bold rounded-lg"
        onClick={playGame}
      >
        Play
      </button>

      <img src={Person3} alt="persion" className="w-44 h-44 mt-3 mx-auto" />
      {/* play modal */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className="bg-[#E3109E] p-5 rounded-lg m-auto"
        sx={{ width: { xs: "90%", md: 980 }, height: "max-content" }}
      >
        <Box className="lg:flex bg-[#000]">
          <Box className="p-[30px] flex flex-col justify-between">
            <h1 className="text-5xl font-bold text-[#E3109E] leading-[4rem]">
              You're playing to win{" "}
              {Web3.utils.fromWei(
                BigNumber(gameInfo?.bet || "0")
                  .multipliedBy(BigNumber(gameInfo?.numPlayers || "0"))
                  .toString()
              )}{" "}
              MATIC
            </h1>
            <Box>
              <p className="text-2xl font-bold text-gray-400 m-0 leading-8">
                Confirm you bet of{" "}
                {Web3.utils.fromWei(BigNumber(gameInfo?.bet || "0").toString())}{" "}
                MATIC:
              </p>
              <p className="text-xl font-bold text-gray-400 mb-2 leading-7">
                Validate the transaction in your wallet.
              </p>
              <p
                className="text-gray-400 leading-6 cursor-pointer"
                onClick={() => setOpen(false)}
              >
                Click here if the screen doesn't refresh after validation
              </p>
            </Box>
          </Box>
          <Box
            component="img"
            sx={{ width: { xs: "100%", md: "50%" } }}
            alt="Rolling"
            src={Rolling}
          />
        </Box>
      </Modal>
      {/* well played modal */}
      <Modal
        open={resOpen}
        onClose={() => {
          setResOpen(false);
          if (typeof loadData === "function") {
            loadData();
          }
        }}
        className="bg-[#E3109E] p-5 rounded-lg m-auto"
        sx={{ width: { xs: "90%", md: 980 }, height: "max-content" }}
      >
        <Box className="lg:flex bg-[#000]">
          <Box
            component="img"
            sx={{ width: { xs: "100%", md: "60%" } }}
            className="p-4 lg:mr-[-120px]"
            alt="Rolling"
            src={Person3}
          />
          <Box className="p-[30px] flex flex-col justify-between">
            <h1 className="text-5xl font-bold text-[#E3109E] leading-[4rem] text-right">
              Well Played!
            </h1>
            <Grid container spacing={2}>
              {[0, 1, 2].map((i, index) => (
                <Grid item xs={4} key={index}>
                  <img
                    className="rounded-lg"
                    alt="?"
                    src={
                      PINK_DICE_IMAGES[
                      rollResults[myaccount]?.result?.length > i
                        ? parseInt(rollResults[myaccount]?.result[i])
                        : 0
                      ]
                    }
                  />
                </Grid>
              ))}
            </Grid>
            <Box className="w-full px-3 mt-2">
              <Box
                className="bg-[#a6a6a6] rounded-lg text-center p-3 cursor-pointer"
                onClick={() => {
                  setResOpen(false);
                  setCompareOpen(true);
                }}
              >
                <p className="text-xl font-bold">
                  See how you compare to other players
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* compare modal */}
      <Modal
        open={compareOpen}
        onClose={() => {
          setCompareOpen(false);
          if (typeof loadData === "function") {
            loadData();
          }
        }}
        className="bg-[#E3109E] p-5 rounded-lg m-auto"
        sx={{
          width: { xs: "90%", md: 980 },
          height: "max-content",
          maxHeight: 600,
          overflowY: "auto",
        }}
      >
        <Box className="bg-[#000] p-4">
          { // not sure who is winner
            !finished && <>
              <h1 className="text-5xl font-bold text-[#E3109E] leading-[4rem] text-center">
                Nice roll!
              </h1>
            </>
          }
          {// leading
            finished && leadingStatus && <>
              <h1 className="text-5xl font-bold text-[#E3109E] leading-[4rem] text-center">
                You're a winner!
              </h1>
              <p className="text-gray-400 text-xl text-center leading-6 mt-2 mb-5">
                You win the Prize of{" "}
                {Web3.utils.fromWei(
                  BigNumber(gameInfo?.bet || "0")
                    .multipliedBy(BigNumber(gameInfo?.numPlayers || "0"))
                    .toString()
                )}{" "}
                MATIC and LOPxp tokens for your participation
              </p>
            </>
          }
          { // loser
            finished && !leadingStatus && <>
              <h1 className="text-5xl font-bold text-[#E3109E] leading-[4rem] text-center">
                You're a loser!
              </h1>
              <p className="text-gray-400 text-xl text-center leading-6 mt-2 mb-5">
                No more chances to win the{" "}
                {Web3.utils.fromWei(
                  BigNumber(gameInfo?.bet || "0")
                    .multipliedBy(BigNumber(gameInfo?.numPlayers || "0"))
                    .toString()
                )}{" "}
                MATIC prize, but you will earn LOPxp tokens for your
                participation
              </p>
            </>
          }
          <Box className="flex flex-wrap justify-center">
            {Object.keys(rollResults).map((key, index) => (
              <Box key={index} sx={{ width: 165 }} className="mx-2 relative">
                <img
                  alt="?"
                  src={key === myaccount ? Person3 : Person1}
                  width="100%"
                  className="p-1 rounded-lg bg-[#a6a6a6]"
                />
                {key === myaccount ? (
                  <Box className="flex justify-between mt-2">
                    {[0, 1, 2].map((i, index1) => (
                      <Box
                        key={index1}
                        component="img"
                        className="rounded-lg"
                        sx={{ width: 50 }}
                        alt="?"
                        src={
                          PINK_DICE_IMAGES[
                          rollResults[key]["result"]?.length > i
                            ? parseInt(rollResults[key]["result"][i])
                            : 0
                          ]
                        }
                      />
                    ))}
                  </Box>
                ) : (
                  <Box className="flex justify-between mt-2">
                    {[0, 1, 2].map((i, index1) => (
                      <Box
                        key={index1}
                        component="img"
                        className="rounded-lg"
                        sx={{ width: 50 }}
                        alt="?"
                        src={
                          DICE_IMAGES[
                          rollResults[key]["result"]?.length > i
                            ? parseInt(rollResults[key]["result"][i])
                            : 0
                          ]
                        }
                      />
                    ))}
                  </Box>
                )}
                <h1
                  className={`text-6xl font-bold ${key === myaccount ? "text-[#E3109E]" : "text-gray-400"
                    } absolute left-[50%] bottom-[72px] translate-x-[-50%]`}
                >
                  {rollResults[key]["sum"]}
                </h1>
              </Box>
            ))}
          </Box>
          <p className="text-gray-400 text-xl text-center leading-6 mt-5">
            Share with your friends to find more players
          </p>
          <Box className="flex flex-wrap justify-center mt-3">
            {/*
            <Box
              component="img"
              className="rounded-lg m-2"
              sx={{ width: 185 }}
              alt="protocol"
              src={LensProtocolImg}
            />
            <Box
              component="img"
              className="rounded-lg m-2"
              sx={{ width: 185 }}
              alt="farcaster"
              src={FarcasterImg}
            />
            */}
            <TwitterShareButton
              url={window.location.href}
              title="Join my clash and win LOPxp"
            >
              <Box
                component="img"
                className="rounded-lg m-2"
                sx={{ width: 185, border: "1px solid #fff" }}
                alt="xlogo"
                src={XlogoImg}
              />
            </TwitterShareButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default function PlayClash() {
  const { diceId } = useParams();

  const [numPleayer, setNumplayer] = useState(0);
  const [players, setPlayers] = useState([]);
  const [gameInfo, setGameInfo] = useState({});
  const [winner, setWinner] = useState("");
  const [rollResults, setRollResults] = useState({});
  const [account, setAccount] = useState("");

  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    const writeWeb3 = await connectWallet();

    if (typeof writeWeb3 === "object") {
      const accounts = await writeWeb3.eth.getAccounts(function (
        error,
        accounts
      ) {
        if (error) {
          return "";
        }
        return accounts[0];
      });

      setAccount(accounts[0]);
    }

    setLoading(true);

    const web3 = getHttpWeb3();
    const degenDiceMatic = new web3.eth.Contract(
      DegenDiceMaticAbi,
      DEGEN_DICE_MATIC_ADDRESS
    );
    const _gameInfo =
      (await degenDiceMatic.methods.games(parseInt(diceId)).call()) || {};
    setNumplayer(parseInt(_gameInfo.numPlayers) || 0);
    setGameInfo(_gameInfo);

    const _players = await degenDiceMatic.methods.getPlayers(diceId).call();
    setPlayers(_players);

    let _winner = "";
    let sum = 0;
    let rollResultsObj = {};
    for (let index = 0; index < _players.length; index++) {
      const _rollResults = await degenDiceMatic.methods
        .getRollResults(diceId, _players[index])
        .call();
      rollResultsObj = { ...rollResultsObj, [_players[index]]: _rollResults };

      const personSum = _rollResults.reduce(function (total, num) {
        return parseInt(total) + parseInt(num);
      }, 0);

      if (sum < personSum) {
        sum = personSum;
        _winner = _players[index];
      }
    }

    setRollResults(rollResultsObj);
    setWinner(_winner);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [diceId]);

  return (
    <>
      <Layout>
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row justify-between m-2 md:m-5 bg-black rounded-lg">
          <div className="flex flex-col mt-10 mx-auto space-y-4 ml-4">
            <h1 className="text-3xl md:text-5xl font-bold text-[#E3109E]">
              Degen Dice Clash #{diceId}
            </h1>
            <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row justify-start">
              <div className="md:w-1/4">
                <div
                  className="bg-[#a6a6a6] rounded-lg flex flex-col mx-auto w-3/4 text-center items-center align-center justify-center"
                  style={{ height: 70 }}
                >
                  <img
                    alt="matic logo"
                    src={Matic}
                    className="md:w-1/2 h-1/2 mx-auto align-center justify-center items-center"
                  />
                  <p className="text-center">MATIC</p>
                </div>
              </div>
              <div className="md:w-1/4">
                <div
                  className="bg-[#a6a6a6] rounded-lg flex flex-col mx-auto w-3/4 text-center items-center align-center justify-center"
                  style={{ height: 70 }}
                >
                  <p className="w-1/2 h-1/2 mx-auto align-center justify-center items-center">
                    {gameInfo?.numPlayers}
                  </p>
                  <p className="text-center">Player Clash</p>
                </div>
              </div>
              <div className="md:w-1/4">
                <div
                  className="bg-[#a6a6a6] rounded-lg flex flex-col mx-auto w-3/4 text-center items-center align-center justify-center"
                  style={{ height: 70 }}
                >
                  <p className="w-1/2 h-1/2 mx-auto align-center justify-center items-center">
                    BET
                  </p>
                  <p className="text-center">
                    {Web3.utils.fromWei(gameInfo?.bet || "")} Matic
                  </p>
                </div>
              </div>
              <div className="md:w-1/4">
                <div
                  className="bg-[#a6a6a6] rounded-lg flex flex-col mx-auto w-3/4 text-center items-center align-center justify-center"
                  style={{ height: 70 }}
                >
                  <p className="w-1/2 h-1/2 mx-auto align-center justify-center items-center">
                    Bonus
                  </p>
                  <p className="text-center">
                    {Web3.utils.fromWei(gameInfo?.bonus || "")} Matic
                  </p>
                </div>
              </div>
            </div>
            {gameInfo?.gameState === "0" ? (
              <div className="flex justify-center w-full">
                <div className="bg-[#E3109E]  rounded-lg p-2 w-3/4 flex justify-center flex-col text-center">
                  <p>Throw the highest dice to</p>
                  <p className="text-2xl font-bold">
                    WIN{" "}
                    {Web3.utils.fromWei(
                      BigNumber(gameInfo?.bet || "0")
                        .multipliedBy(BigNumber(gameInfo?.numPlayers || "0"))
                        .toString()
                    )}{" "}
                    MATIC
                  </p>
                </div>
              </div>
            ) : (
              <div className="w-full px-3">
                <div className="bg-[#a6a6a6] rounded-lg text-center p-3">
                  <h1 className="text-5xl font-bold text-[#E3109E]">
                    CLASH ENDED
                  </h1>
                  <p className="text-3xl">
                    {Web3.utils.fromWei(
                      BigNumber(gameInfo?.bet || "0")
                        .multipliedBy(BigNumber(gameInfo?.numPlayers || "0"))
                        .toString()
                    )}{" "}
                    MATIC PRIZE DISTRIBUTED
                  </p>
                </div>
              </div>
            )}

            <div className="flex justify-center w-full flex-col pl-4">
              <div className="text-gray-400">Rules</div>
              <ul className="list-disc text-gray-400 font-bold mb-5">
                <li>
                  {gameInfo?.numPlayers} players compete for the highest Dice
                  score to win the Prize
                </li>
                <li>100% of the bets are distributed among players</li>
                <li>
                  All Players will earn LOPxp reward token if clash is completed
                </li>
              </ul>
            </div>
          </div>
          <div>
            <img
              alt="BannerImg"
              src={CreateBanner}
              style={{ maxHeight: 400 }}
              className="rounded-lg"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full justify-center px-20 mt-12 mb-20">
          {!loading &&
            players.map((player, index) => {
              return (
                <PersonCard
                  key={"left" + index}
                  gameInfo={gameInfo}
                  rollResult={rollResults[player]}
                  winner={winner === player}
                  myOwn={account === player}
                />
              );
            })}
          {!loading && numPleayer - players.length > 0 && (
            <PlayCard loadData={loadData} />
          )}
          {!loading &&
            numPleayer - players.length - 1 > 0 &&
            new Array(numPleayer - players.length - 1)
              .fill(0)
              .map((player, index) => {
                return (
                  <PersonCard
                    key={"left" + index}
                    gameInfo={gameInfo}
                    rollResult={rollResults[player]}
                    winner={winner === player}
                    account={account}
                    myOwn={account === player}
                  />
                );
              })}
        </div>
      </Layout>
    </>
  );
}
