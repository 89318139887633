import React from "react";
import Blockies from "react-blockies";
import Web3 from "web3";

import { getShortAddress } from "../utils";

export default function LeaderboardComp({ playerData }) {
  return (
    <div className="ml-4 mb-5 pr-4">
      <p className="text-3xl text-[#E3109E] text-center font-bold mb-1">
        All Clashes Leaderboard
      </p>
      <p className="text-center mb-5 text-xl">Who is leading the clash?</p>
      <div className="overflow-x-auto pb-5">
        <table className="table-auto w-full rounded-lg">
          <thead className="bg-gray-300 rounded-lg p-5 h-20">
            <tr className="text-left bg-gray-300 rounded-lg p-5 italic">
              <th className="text-center px-2">Player</th>
              <th className="px-2">Clashes Created</th>
              <th className="px-2">Clashes Played</th>
              <th className="px-2">Earned</th>
              <th className="px-2">Earned Count</th>
              <th className="px-2">Earned XP</th>
              <th className="px-2">Earned XP Count</th>
            </tr>
          </thead>
          <tbody>
            {playerData.map((player, index) => (
              <tr key={index}>
                <td className="pt-4">
                  <div className="flex flex-col md:flex-row mx-auto items-center align-center justify-center md:space-x-4">
                    <Blockies
                      seed={player?.player}
                      size={10}
                      scale={6}
                      color="#ff07d3"
                      bgColor="#f1e38f"
                      spotColor="#abc"
                      className="identicon"
                    />
                    <p className="font-bold text-xl ">{getShortAddress(player?.player)}</p>
                  </div>
                </td>

                <td className="px-2">{player?.created}</td>
                <td className="px-2">{player?.played}</td>
                <td className="px-2">{Web3.utils.fromWei(player?.earned)} Matic</td>
                <td className="px-2">{player?.earnedCount}</td>
                <td className="px-2">{Web3.utils.fromWei(player?.earnXp)} LOPxp</td>
                <td className="px-2">{player?.earnXpCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
