import React from "react";

//import LoadingAnimation from "../assets/loading.gif";
import "./loading.css";

export default function LoadingTransaction({ opacity }) {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: "999999",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: `rgba(0,0,0,${opacity || "0.65"})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 50,
      }}
    >
      {/*<img src={LoadingAnimation} alt="loading" style={{ width: 180 }} />*/}
      {/* <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 300 100"
        style={{ "enable-background": "new 0 0 350 100;" }}
        className="dicesvg"
      >
        <rect
          x="60"
          y="48"
          id="dice1"
          fill="url(#side)"
          width="50"
          height="50"
          rx="5"
        />
        <rect
          x="125"
          y="48"
          id="dice2"
          fill="url(#side)"
          width="50"
          height="50"
          rx="5"
        />
        <rect
          x="190"
          y="48"
          id="dice3"
          fill="url(#side)"
          width="50"
          height="50"
          rx="5"
        />

        <defs>
          <pattern id="side1" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="25" cy="25" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side2" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="11" cy="39" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side3" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="25" cy="25" r="5" />
            <circle cx="11" cy="39" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side4" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="39" r="5" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="11" cy="39" r="5" />
            <circle cx="11" cy="11" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side5" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="39" r="5" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="25" cy="25" r="5" />
            <circle cx="11" cy="39" r="5" />
            <circle cx="11" cy="11" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side6" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="39" r="5" />
            <circle cx="39" cy="25" r="5" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="11" cy="39" r="5" />
            <circle cx="11" cy="25" r="5" />
            <circle cx="11" cy="11" r="5" />
          </pattern>
        </defs>
      </svg> */}
      <h3 style={{
        fontSize: 40,
      }}>
        Confirm the transaction to Create the Clash
      </h3>
    </div>
  );
}
