import React from "react";
import Hand2 from "../assets/Hand2 Img.png";

export default function LandingTile2() {
  return (
    <div className="relative flex justify-between rounded-lg">
      <p className="absolute top-5 right-5 font-bold text-5xl text-[#E3109E] whitespace-pre-line">
        Create <br />a Clash
      </p>
      <img alt="tile2" src={Hand2} className="rounded-lg" />
    </div>
  );
}
