import React, { useEffect } from "react";

import Layout from "../components/Layout";

import { DEGEN_DICE_MATIC_ADDRESS } from "../constants";
import { getHttpWeb3 } from "../utils";
import DegenDiceMaticAbi from "../constants/DegenDiceMatic.json"

import LeaderboardComp from "../components/LeaderboardComp";
import Banner from "../assets/Fullbanner.png";

export default function Leaderboard() {
  const [playerData, setPlayerData] = React.useState([]);

  useEffect(() => {
    (async () => {
      const web3 = getHttpWeb3();
      const degenDiceMatic = new web3.eth.Contract(DegenDiceMaticAbi, DEGEN_DICE_MATIC_ADDRESS);

      const _boardId = await degenDiceMatic.methods.boardId().call() || 0;

      const _playData = [];

      for (let i = 1; i <= _boardId; i++) {
        const _leaderBoardData = await degenDiceMatic.methods.leaderBoard(i).call() || {};
        const created = _leaderBoardData?.created;
        const earnXp = _leaderBoardData?.earnXp;
        const earnXpCount = _leaderBoardData?.earnXpCount;
        const earned = _leaderBoardData?.earned;
        const earnedCount = _leaderBoardData?.earnedCount;
        const played = _leaderBoardData?.played;
        const player = await degenDiceMatic.methods.playerIndices(i).call() || "";

        const _playInfo = {
          created, earnXp, earnXpCount, earned, earnedCount, played, player,
        };

        _playData.push(_playInfo);

        setPlayerData([..._playData]);
      }

    })()
  }, [])
  return (
    <Layout>
      <div className="flex align-center mx-auto items-center justify-center mt-2 w-11/12 mb-4">
        <img alt="Banner" src={Banner} />
      </div>
      <LeaderboardComp playerData={playerData} />
    </Layout>
  );
}
