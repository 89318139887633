// PlayerCircles.js
import React from "react";
import { BsCircleFill } from "react-icons/bs";

const PlayerCircles = ({ active, total }) => {
  let circles = [];
  for (let i = 0; i < total; i++) {
    circles.push(
      <BsCircleFill
        key={i}
        className={i < active ? "text-black" : "text-[#E3109E]"}
      />
    );
  }
  return (
    <>
      <div className="flex items-center justify-start space-x-1">{circles}</div>
    </>
  );
};

export default PlayerCircles;
