import { Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import CreateClash from "./pages/CreateClash";
import PlayClash from "./pages/PlayClash";
import AllClashes from "./pages/AllClashes";
import Leaderboard from "./pages/Leaderboard";
import Rules from "./pages/Rules";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/create-clash" element={<CreateClash />} />
      <Route path="/all-clashes" element={<AllClashes />} />
      <Route path="/leaderboard" element={<Leaderboard />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/play/:diceId" element={<PlayClash />} />
      <Route path="/play" element={<PlayClash />} />
    </Routes>
  );
}

export default App;
