import React from "react";
import Layout from "../components/Layout";
import Banner from "../assets/Fullbanner.png";
import LandingTile1 from "../components/LandingTile1";
import LandingTile2 from "../components/LandingTile2";
import LandingTile3 from "../components/LandingTile3";
import ClashesLandingMatic from "../components/ClashesLandingMatic";
import ClashesLandingDegen from "../components/ClashesLandingDegen";
import ClashesLandingBonsai from "../components/ClashesLandingBonsai";
export default function Landing() {
  return (
    <Layout>
      <div className="flex align-center mx-auto items-center justify-center mt-2 w-11/12">
        <img alt="Banner" src={Banner} />
      </div>

      <div className="flex flex-col mx-auto text-center mt-10 font-sans">
        <p className="text-4xl md:text-5xl text-[#E3109E] font-bold">
          Bet. Throw. Win.
        </p>
        <p className="text-xl md:text-3xl">The Ballsy Web3 Dice Challenge</p>
      </div>
      <div className="flex flex-col md:flex-row justify-evenly space-y-4 md:space-x-4 md:space-y-0 p-4">
        <LandingTile1 />
        <LandingTile2 />
        <LandingTile3 />
      </div>
      <div className="flex flex-row justify-center space-x-4">
        <div className="w-full">
          <ClashesLandingMatic />
        </div>
        {/* <div className="w-1/3">
          <ClashesLandingDegen />
        </div> */}
        {/* <div className="w-1/3">
          <ClashesLandingBonsai />
        </div> */}
      </div>
    </Layout>
  );
}
