import React from "react";
import Hand1 from "../assets/Hand Img.png";

export default function LandingTile1() {
  return (
    <div className="relative flex justify-between rounded-lg">
      <p className="absolute top-5 left-5 font-bold text-5xl text-white ">
        Win Big
      </p>
      <img alt="tile1" src={Hand1} className="rounded-lg" />
    </div>
  );
}
