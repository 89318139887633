import React, { useEffect } from "react";
import gsap from "gsap";

//import LoadingAnimation from "../assets/loading.gif";
import "./loading.css";

export default function Loading({ opacity }) {
  const playTL = () => {
    var tl = gsap.timeline({
      defaults: { transformOrigin: "center", opacity: 1, ease: "bounce.out" },
    });
    tl.to("#dice1,#dice2,#dice3", {
      y: -30,
      rotate: 0,
      opacity: 1,
      duration: 0,
      onComplete: add,
    });

    function add() {
      tl.to("#dice1", { y: -30, rotation: "+=360", ease: "none", duration: 1, repeat: -1});
      tl.to("#dice2", { y: -30, rotation: "+=360", ease: "none", duration: 1, repeat: -1}, "-=1.8");
      tl.to("#dice3", { y: -30, rotation: "+=360", ease: "none", duration: 1, repeat: -1}, "-=1.8");
    }
  };

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  useEffect(() => {
    playTL();
    const interval = setInterval(() => {
      for (let i = 1; i < 4; i++) {
        var rndInt = randomIntFromInterval(1, 6);
        var element = document.getElementById("dice" + i);
        if (element) {
          element.style.fill = "url(#side" + rndInt + ")";
        }
      }    
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        zIndex: "999999",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: `rgba(0,0,0,${opacity || "0.65"})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 50,
      }}
    >
      {/*<img src={LoadingAnimation} alt="loading" style={{ width: 180 }} />*/}
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 300 100"
        style={{ "enable-background": "new 0 0 350 100;" }}
        className="dicesvg"
      >
        <rect
          x="60"
          y="48"
          id="dice1"
          fill="url(#side)"
          width="50"
          height="50"
          rx="5"
        />
        <rect
          x="125"
          y="48"
          id="dice2"
          fill="url(#side)"
          width="50"
          height="50"
          rx="5"
        />
        <rect
          x="190"
          y="48"
          id="dice3"
          fill="url(#side)"
          width="50"
          height="50"
          rx="5"
        />

        <defs>
          <pattern id="side1" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="25" cy="25" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side2" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="11" cy="39" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side3" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="25" cy="25" r="5" />
            <circle cx="11" cy="39" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side4" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="39" r="5" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="11" cy="39" r="5" />
            <circle cx="11" cy="11" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side5" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="39" r="5" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="25" cy="25" r="5" />
            <circle cx="11" cy="39" r="5" />
            <circle cx="11" cy="11" r="5" />
          </pattern>
        </defs>

        <defs>
          <pattern id="side6" viewBox="0,0,50,50" width="100%" height="100%">
            <rect fill="#F2F2F2" width="50" height="50" />
            <circle cx="39" cy="39" r="5" />
            <circle cx="39" cy="25" r="5" />
            <circle cx="39" cy="11" r="5" />
            <circle cx="11" cy="39" r="5" />
            <circle cx="11" cy="25" r="5" />
            <circle cx="11" cy="11" r="5" />
          </pattern>
        </defs>
      </svg>
      <h3>
        Dice is spinning..your roll will be revealed when the transaction is
        confirmed.
      </h3>
    </div>
  );
}
