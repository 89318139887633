import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import BannerImage from "../assets/Degen Banner.png";

import { connectWallet, getShortAddress } from "../utils";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";

export default function Header() {
  const nav = useNavigate();

  const [account, setAccount] = useState("");
  // const [balance, setBalance] = useState("");

  const disconnectWalletHandler = async () => {
    setAccount("");
    return toast("Disconnected success", customToastSuccessStyle);
  };

  const connectWalletHandler = async () => {
    const web3 = await connectWallet();
    if (web3 && typeof web3 !== "string") {
      const accounts = await web3.eth.getAccounts(function (error, accounts) {
        if (error) {
          return "";
        }
        return accounts[0];
      });

      setAccount(accounts[0]);
      const _balance = await web3.eth.getBalance(accounts[0]);

      if (_balance === "") {
        return toast("Your balance is 0", customToastStyle);
      }

      // setBalance(_balance);

      return toast("Connected success", customToastSuccessStyle);
    }
  };
  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 justify-between cursor-pointer pt-4">
      <Toaster position="top-right" />
      <div className="ml-10">
        <img
          alt="logo"
          src={BannerImage}
          className="logo"
          onClick={() => nav("/")}
        />
      </div>
      <ul className="flex flex-col md:flex-row space-y-3 md:space-y-0 ml-10 mr-3 italic font-sans">
        <li
          className="font-bold"
          onClick={() => {
            nav("/");
          }}
        >
          Home|
        </li>
        <li
          onClick={() => {
            nav("/create-clash");
          }}
        >
          Create a Clash|
        </li>
        <li
          onClick={() => {
            nav("/all-clashes");
          }}
        >
          All Clashes|
        </li>
        <li
          onClick={() => {
            nav("/leaderboard");
          }}
        >
          Leaderboard|
        </li>
        <li
          onClick={() => {
            nav("/rules");
          }}
        >
          Rules|
        </li>
        <li
          onClick={() => {
            if (account === "") {
              connectWalletHandler();
            } else {
              disconnectWalletHandler();
            }
          }}
          style={{ color: account === "" ? "black" : "blue" }}
        >
          {account === "" ? "Connect to Wallet" : getShortAddress(account)}
        </li>
      </ul>
    </div>
  );
}
