import React from "react";
import Layout from "../components/Layout";
import CreateBanner from "../assets/CreateBanner.png";

export default function Rules() {
  return (
    <Layout>
      <div
        className="flex flex-col space-y-5 md:flex-row justify-between m-2 md:m-5 bg-black rounded-lg"
        style={{ minHeight: "calc(100vh - 80px)" }}
      >
        <div className="mt-10 mx-auto md:pl-5">
          <div className="flex flex-col space-y-5 p-3 md:p-0">
            <h1 className="text-4xl md:text-6xl font-bold text-[#E3109E]">
              Play Clash Rules
            </h1>
            <ul className="list-decimal flex flex-col space-y-3 text-gray-400 text-xl md:text-2xl font-bold ml-8">
              <li>Open an Active Clash</li>
              <li>Place your bet and roll the dice.</li>
              <li>The highest score wins!</li>
              <li>
                When everyone has played, the winners will be paid directly to
                their wallet.
              </li>
            </ul>
          </div>
          <div className="flex flex-col mt-10 mx-auto space-y-5 p-3 md:p-0">
            <h1 className="text-4xl md:text-6xl font-bold text-[#E3109E]">
              Create Clash Rules
            </h1>
            <ul className="list-decimal flex flex-col space-y-3 text-gray-400 text-xl md:text-2xl font-bold ml-8">
              <li>Open the Create Clash page.</li>
              <li>
                Choose your chain, set the bet for every player, set the number
                of players, and optionally set a bonus.
              </li>
              <li>Sign the blockchain transaction.</li>
              <li>The Clash is ready to play!</li>
            </ul>
          </div>
        </div>
        <div>
          <img
            alt="BannerImg"
            src={CreateBanner}
            style={{ maxHeight: 400 }}
            className="rounded-lg"
          />
        </div>
      </div>
    </Layout>
  );
}
