import React from "react";
import BigNumber from "bignumber.js";
import Web3 from "web3";

// import { getHttpWeb3 } from "../utils";
// import { DEGEN_DICE_MATIC_ADDRESS } from "../constants";

// import DegenDiceMaticAbi from "../constants/DegenDiceMatic.json"

import Person1 from "../assets/Person1.png";

import QuestionDie from "../assets/QuestionDie.png";
import Dice1Img from "../assets/1die.png";
import Dice2Img from "../assets/2die.png";
import Dice3Img from "../assets/3die.png";
import Dice4Img from "../assets/4die.png";
import Dice5Img from "../assets/5die.png";
import Dice6Img from "../assets/6die.png";

import QuestionPinkDie from "../assets/PinkQDie.jpg";
import PinkDice1Img from "../assets/Pink1Die.jpg";
import PinkDice2Img from "../assets/Pink2Die.png";
import PinkDice3Img from "../assets/Pink3Die.png";
import PinkDice4Img from "../assets/Pink4Die.png";
import PinkDice5Img from "../assets/Pink5Die.png";
import PinkDice6Img from "../assets/Pink6Die.png";

const DICE_IMAGES = [
  QuestionDie,
  Dice1Img,
  Dice2Img,
  Dice3Img,
  Dice4Img,
  Dice5Img,
  Dice6Img,
];
const PINK_DICE_IMAGES = [
  QuestionPinkDie,
  PinkDice1Img,
  PinkDice2Img,
  PinkDice3Img,
  PinkDice4Img,
  PinkDice5Img,
  PinkDice6Img,
];

export const PersonCard = ({ gameInfo, rollResult, winner, myOwn }) => {
  // const [rollResult, setRollResult] = useState(["0", "0", "0"])
  // useEffect(() => {
  //     (async () => {
  //         const web3 = getHttpWeb3();
  //         const degenDiceMatic = new web3.eth.Contract(DegenDiceMaticAbi, DEGEN_DICE_MATIC_ADDRESS);

  //         if (player) {
  //             const _rollResults = await degenDiceMatic.methods.getRollResults(diceId, player).call();
  //             setRollResult(_rollResults);
  //         }
  //     })()

  // }, [diceId, player])
  return (
    <div className="flex justify-center flex-col p-4 relative">
      {myOwn && <p style={{ position: "absolute", top: -15 }}>Your Roll</p>}
      {gameInfo?.gameState === "1" && winner ? (
        <div className="flex flex-row w-44 justify-between">
          {[0, 1, 2].map((i, index) => (
            <div className="w-14 bg-black" key={index}>
              <img
                alt="?"
                src={
                  PINK_DICE_IMAGES[
                    rollResult?.length > i ? parseInt(rollResult[i]) : 0
                  ]
                }
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-row w-44 justify-between">
          {[0, 1, 2].map((i, index) => (
            <div className="w-14 bg-black" key={index}>
              <img
                alt="?"
                src={
                  DICE_IMAGES[
                    rollResult?.length > i ? parseInt(rollResult[i]) : 0
                  ]
                }
              />
            </div>
          ))}
        </div>
      )}

      <img
        src={Person1}
        alt="persion"
        className={`w-44 h-44 mt-3 rounded-lg ${
          gameInfo?.gameState === "1" && winner
            ? "border-solid border-4 border-[#E3109E]"
            : ""
        }`}
      />
      {gameInfo?.gameState === "1" && (
        <div className={`absolute left-0 w-full text-center bottom-[30px]`}>
          <h1
            className={`text-4xl font-bold ${
              winner ? "text-[#E3109E]" : "text-gray-400"
            } `}
          >
            {winner ? "WON" : "LOSER"}
          </h1>
          {winner && (
            <h1 className="text-4xl font-bold text-[#E3109E] w-full text-center">
              {Web3.utils.fromWei(
                BigNumber(gameInfo?.bet || "0")
                  .multipliedBy(BigNumber(gameInfo?.numPlayers || "0"))
                  .toString()
              )}{" "}
              MATIC
            </h1>
          )}
        </div>
      )}
    </div>
  );
};
