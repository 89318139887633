import React from "react";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { useNavigate } from "react-router-dom";

import PlayerCircles from "../components/PlayerCircles";

import Matic from "../assets/Matic .png";
import Duo1 from "../assets/Duo1.png";

export default function AllClashesTable({ clashesData }) {
  const nav = useNavigate();

  const goToGamePage = (gameId) => {
    nav("/play/" + gameId);
  };

  return (
    <div className="ml-4 mb-5 pr-4">
      <p className="text-3xl text-[#E3109E] text-center font-bold mb-1">
        All the Clashes
      </p>
      <p className="text-center mb-5 text-xl">Chose a game to join</p>
      <div className="relative overflow-x-auto">
        <table className="table-auto w-full rounded-lg">
          <thead className="bg-gray-300 rounded-lg p-5 h-20">
            <tr className="text-left bg-gray-300 rounded-lg p-5 italic">
              <th className="text-center">Clash</th>
              <th className="px-2">Players</th>
              <th className="px-2">Chain</th>
              <th className="px-2">Clash Type</th>
              <th className="px-2">Bet</th>
              <th>Prize</th>
              <th>Bonus</th>
            </tr>
          </thead>
          <tbody>
            {clashesData.map((clash, index) => (
              <tr key={index}>
                <td className="pt-4 px-2">
                  <div
                    className="flex flex-col md:flex-row mx-auto items-center align-center justify-center md:space-x-4 cursor-pointer"
                    onClick={() => {
                      goToGamePage(index + 1);
                    }}
                  >
                    <img alt="img" src={Duo1} className="md:w-1/5" />
                    <p className="font-bold text-xl ">Clash #{clash.gameId}</p>
                  </div>
                </td>
                <td className="px-2">
                  <PlayerCircles
                    active={clash?.players?.length}
                    total={parseInt(clash?.numPlayers)}
                  />
                </td>
                <td className="px-2">
                  <img src={Matic} alt="Matic logo" className="w-8 h-8" />
                  <p>Matic</p>
                </td>
                <td className="px-2">{clash?.numPlayers} Player Clash</td>
                <td className="px-2">
                  {Web3.utils.fromWei(clash?.bet || "")} Matic
                </td>
                <td>
                  {Web3.utils.fromWei(
                    BigNumber(clash?.bet || "")
                      .multipliedBy(BigNumber(clash?.numPlayers || ""))
                      .toString()
                  )}{" "}
                  Matic
                </td>
                <td>{Web3.utils.fromWei(clash?.bonus || "")} Matic</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
