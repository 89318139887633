import React from "react";
import Hand3 from "../assets/Hand 3 Img.png";

export default function LandingTile3() {
  return (
    <div className="relative flex justify-between rounded-lg">
      <p className="absolute top-5 left-5 font-bold text-5xl text-white whitespace-pre-line">
        Last <br /> Player
      </p>
      <img alt="tile3" src={Hand3} className="rounded-lg" />
    </div>
  );
}
