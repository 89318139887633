import React, { useState } from "react";
import toast from "react-hot-toast";
import BigNumber from "bignumber.js";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout";

import { connectWallet } from "../utils";
import { DEGEN_DICE_MATIC_ADDRESS } from "../constants";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";

import DegenDiceMaticAbi from "../constants/DegenDiceMatic.json";

import CreateBanner from "../assets/CreateBanner.png";
import Matic from "../assets/Matic .png";
import Degen from "../assets/DegenBlack.png";
import Bonsai from "../assets/BonsaiLogo.png";

import LoadingTransaction from "../components/LoadingTransaction";

const NUMBER_OF_OPPONENTS_LIST = [2, 3, 4, 5];

export default function CreateClash() {
  const nav = useNavigate();

  const [betAmount, setBetAmount] = useState(0);
  const [opponentNum, setOpponentNum] = useState(NUMBER_OF_OPPONENTS_LIST[0]);
  const [bonus, setBonus] = useState(0);
  const [loading, setLoading] = useState(false);

  const comingSoon = () => {
    toast("This chain is coming soon", customToastStyle);
  };

  const createClash = async () => {
    const web3 = await connectWallet();

    if (typeof web3 === "object") {
      const accounts = await web3.eth.getAccounts(function (error, accounts) {
        if (error) {
          return "";
        }
        return accounts[0];
      });

      const account = accounts[0];
      const degenDiceMatic = new web3.eth.Contract(
        DegenDiceMaticAbi,
        DEGEN_DICE_MATIC_ADDRESS
      );

      const _betAmount = web3.utils.toWei(betAmount.toString());
      const _bonus = web3.utils.toWei(bonus.toString());

      const _balance = await web3.eth.getBalance(account);

      if (BigNumber(_balance).isLessThan(BigNumber(_bonus))) {
        return toast(
          "You don't have enough MATIC amount to set bonus",
          customToastStyle
        );
      }

      setLoading(true);

      await degenDiceMatic.methods
        .createGame(opponentNum, _betAmount, _bonus)
        .send({ from: account, value: _bonus })
        .then(async (res) => {
          toast("Success", customToastSuccessStyle);
          const _nextId = parseInt(
            await degenDiceMatic.methods.nextGameId().call()
          );
          if (_nextId) {
            const id = _nextId - 1;
            nav(`/play/${id}`);
          }
        })
        .catch((err) => {
          toast(
            "There is an error to interact smart contract.",
            customToastStyle
          );
        })

      setLoading(false);
    }
  };

  return (
    <>
      <Layout>
        {loading && <LoadingTransaction />}
        <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 justify-between m-5 bg-black rounded-lg">
          <div className="flex flex-col mt-10 mx-auto space-y-4 p-3 md:p-0">
            <h1 className="text-4xl md:text-6xl font-bold text-[#E3109E]">
              Create a Clash
            </h1>
            <ul className="list-disc text-gray-400 text-xl md:text-2xl font-bold ml-5 md:ml-0">
              <li>Set your parameters</li>
              <li>Create a clash</li>
              <li>Throw the dice</li>
              <li>Invite friends</li>
              <li>Receive LOPxp when the clash is over</li>
            </ul>
          </div>
          <div>
            <img
              alt="BannerImg"
              src={CreateBanner}
              style={{ maxHeight: 400 }}
              className="rounded-lg"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 m-5 bg-black rounded-lg p-2">
          <div className="md:w-1/2 items-center justify-center align-center">
            <p className="text-2xl text-gray-300 font-bold text-center mt-5">
              1. Choose your Chain
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:w-1/2 space-y-4 md:space-y-0">
            <div className="md:w-1/3">
              <div className="bg-white rounded-lg border flex flex-col mx-auto w-1/3 text-center items-center align-center justify-center border-lime-400">
                <img
                  alt="matic logo"
                  src={Matic}
                  className="w-1/2 h-1/2 mx-auto align-center justify-center items-center"
                />
                <p className="text-center">MATIC</p>
              </div>
            </div>
            <div className="md:w-1/3">
              <div
                className="rounded-lg border flex flex-col mx-auto w-1/3 text-center items-center align-center justify-center p-2 cursor-pointer"
                style={{ background: "gray" }}
                onClick={comingSoon}
              >
                <img
                  alt="degen logo"
                  src={Degen}
                  className="w-1/2 h-1/2 mx-auto align-center justify-center items-center"
                />
                <p className="text-center">Degen</p>
              </div>
            </div>
            <div className="md:w-1/3">
              <div
                className="rounded-lg border flex flex-col mx-auto w-1/3 text-center items-center align-center justify-center cursor-pointer"
                style={{ background: "gray" }}
                onClick={comingSoon}
              >
                <img
                  alt="bonsai logo"
                  src={Bonsai}
                  className="w-1/2 h-1/2 mx-auto align-center justify-center items-center"
                />
                <p className="text-center">BONSAI</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 m-5 bg-black rounded-lg p-2">
          <div className="md:w-1/2 items-center justify-center align-center">
            <p className="text-2xl text-gray-300 font-bold text-center mt-5">
              2. Set your bet
            </p>
          </div>

          <div className="flex flex-row md:w-1/2 space-x-2 justify-center align-center">
            <div className="flex flex-row w-3/4 space-x-2">
              <div className="flex w-full h-full align-center">
                <input
                  type="number"
                  defaultValue={0}
                  className="p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  placeholder="   Bet amount"
                  onChange={(e) => {
                    setBetAmount(parseFloat(e.target.value) || 0);
                  }}
                />
              </div>
              <div className="text-white mt-5">(Matic)</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 m-5 bg-black rounded-lg  py-4 px-2">
          <div className="md:w-1/2 items-center justify-center align-center">
            <p className="text-2xl text-gray-300 font-bold text-center  align-center justify-center items-center">
              3. Set the number of players
            </p>
          </div>
          <div className="flex flex-row md:w-1/2 space-x-4 md:space-x-0 align-center justify-center items-center">
            {NUMBER_OF_OPPONENTS_LIST.map((num, index) => (
              <div className="w-1/4" key={index}>
                <div
                  className={`rounded-lg border flex flex-col mx-auto md:w-1/3 text-center items-center align-center justify-center cursor-pointer ${num === opponentNum ? "bg-green-300" : "bg-white"
                    }`}
                  onClick={() => {
                    setOpponentNum(num);
                  }}
                >
                  <p className="text-center">{num}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 m-5 bg-black rounded-lg  py-4 px-2">
          <div className="md:w-1/2 items-center justify-center align-center">
            <p className="text-2xl text-gray-300 font-bold text-center  align-center justify-center items-center">
              4. Set your bonus
            </p>
          </div>

          <div className="flex flex-row md:w-1/2 space-x-2 justify-center align-center">
            <div className="flex flex-row w-3/4 space-x-2">
              <div className="flex w-full h-full align-center">
                <input
                  type="number"
                  defaultValue={0}
                  className="p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  placeholder="   Bonus amount"
                  onChange={(e) => {
                    setBonus(parseFloat(e.target.value) || 0);
                  }}
                />
              </div>
              <div className="text-white mt-5">(Matic)</div>
            </div>
          </div>
        </div>
        <div className="flex justify-center m-5">
          <button
            className="bg-[#E3109E] text-center w-full md:w-3/4 lg:w-2/3 xl:w-1/2 p-4 text-4xl font-bold rounded-lg"
            onClick={createClash}
          >
            Create Clash
          </button>
        </div>
      </Layout>
    </>
  );
}
